export enum TrackingViewPropsSite {
    MA = 'MA',
}

export enum TrackingViewPropsTitle {
    HOMEPAGE = 'homepage',
    ESTIMATE_LANDING = 'estimate_landing',
    ESTIMATE_HOUSE_LANDING = 'estimate_house_landing',
    ESTIMATE_FLAT_LANDING = 'estimate_flat_landing',
    ESTIMATE_RENT_LANDING = 'estimate_rent_landing',
    PRO_DIRECTORY_LANDING = 'pro_directory_landing',
    ABOUT_DATA_EXPERTISE = 'about_data_expertise',
    PERSONAL_CONSULTATION = 'personal_consultation',
}

export enum TrackingViewPropsCategory {
    HOMEPAGE = 'homepage',
    ESTIMATE = 'estimate',
    FIND_PRO = 'find_pro',
    OTHER = 'other',
}

export enum TrackingViewPropsInitiative {
    WL_SHORT_CONTACT = 'wl_short_contact',
}
