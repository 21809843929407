abstract class Config {
    protected static booleanCast(value: string): boolean | string {
        switch (value) {
            case 'false':
                return false;
            case 'true':
                return true;
            default:
                return value;
        }
    }
    abstract get(key: string): boolean | string;
}

export default Config;
