class HttpError extends Error {
    readonly status: number;
    readonly url: string;
    constructor(response: Response) {
        super(`Request to ${response.url} returned ${response.status}`);
        this.status = response.status;
        this.url = response.url;
    }
}

export default HttpError;
