import type { PropsWithChildren } from 'react';
import { Card } from '@ma-react/components';
import clsx from 'clsx';

import styles from './hero-card.module.scss';

export interface HeroCardProps {
    className?: string;
}

const HeroCard = ({ children, className }: PropsWithChildren<HeroCardProps>) => (
    <Card className={clsx(styles['hero-card'], className)} elevation={3}>
        {children}
    </Card>
);

export default HeroCard;
