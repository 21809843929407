import { PageCategory, ThorViewsGA4Interface, ThorViewsInterface } from '@sl/react-thor';
import { useRouter } from 'next/router';

import { TrackingViewPropsSite } from '@/entities/enums/Tracking';
import { useUser } from '@/front/context/UserContext';
import { getPublicConfig } from '@/helpers/config/getConfig';

export const useTrackingProps = (
    pageTitle: string,
    category: PageCategory,
    initiative?: string,
): ThorViewsGA4Interface | ThorViewsInterface => {
    const router = useRouter();
    const [{ user, realtor }] = useUser();
    const { m } = router.query;
    const av_medium = m && typeof m === 'string' ? m : undefined;
    const av_user = user
        ? {
              uid: user.id.toString(),
              status: 'logged',
              agency_id: realtor?.id.toString(),
              intermediary: realtor?.type,
          }
        : { status: 'unlogged' };

    return {
        av_pagetitle: pageTitle,
        av_url_path: router.pathname,
        av_category: category,
        av_environment: getPublicConfig('SENTRY_ENVIRONMENT') as string,
        av_language: 'fr',
        av_platform: 'web',
        av_site: TrackingViewPropsSite.MA,
        av_medium,
        av_user,
        av_initiative: initiative,
    };
};
