import { useState } from 'react';

import type { EventArgs } from '@/entities/types/Ga';
import { gaEvent } from '@/front/helpers/analytics/Ga';

const useGaSendOnce = (events: EventArgs | Array<EventArgs>) => {
    const [hasSend, setHasSend] = useState(false);

    const sendEvent = () => {
        if (hasSend) return;
        if (Array.isArray(events)) {
            events.map((event) => gaEvent(event));
        } else {
            gaEvent(events);
        }
        setHasSend(true);
    };

    return [sendEvent];
};

export default useGaSendOnce;
