import type { GeoApiPlace } from '@/adapters/client/geo-api/GeoApiPlace';
import type Mapper from '@/adapters/Mapper';
import type Place from '@/entities/types/Place';

// GeoAPI can return '0' instead of null: https://meilleursagents.atlassian.net/browse/DE-162
function relevantAdministrativeCodeOrUndefined(value: string | null): string | undefined {
    return value && value !== '0' ? value : undefined;
}

class GeoApiPlaceMapper implements Mapper<GeoApiPlace, Place> {
    perform(item: GeoApiPlace): Place {
        return {
            id: item.id,
            type: item.type,
            name: {
                full: item.name || undefined,
                root: item.name_root || undefined,
                prepositions: item.name_prepositions || undefined,
            },
            slug: item.slug || undefined,
            administrativeCode: relevantAdministrativeCodeOrUndefined(item.administrative_code),
            postalCode: item.postal_code || undefined,
            coordinates: item.coordinates || undefined,
            viewport: item.viewport || undefined,
            boundingBox: item.bounding_box || undefined,
            area: item.area || undefined,
            active: item.active || undefined,
        };
    }
}

export default GeoApiPlaceMapper;
