import { useEffect } from 'react';
import { PageCategory, useThorView } from '@sl/react-thor';

import { useUser } from '@/front/context/UserContext';

import { useTrackingProps } from './useTrackingProps';

export const useThorPageview = (pageTitle: string, category: PageCategory, initiative?: string) => {
    const viewProps = useTrackingProps(pageTitle, category, initiative);
    const view = useThorView({ viewProps });
    const [{ userHasBeenRequested }] = useUser();

    useEffect(() => {
        if (userHasBeenRequested) {
            view();
        }
        // We want to run the effect only when the user information has been retrieved
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userHasBeenRequested]);
};
