import type { SearchItemList } from '@ma-react/components';
import urljoin from 'url-join';

import GeoApiParentsMapper from '@/adapters/client/geo-api/GeoApiParentsMapper';
import type { GeoApiParents, GeoApiPlace } from '@/adapters/client/geo-api/GeoApiPlace';
import GeoApiPlaceMapper from '@/adapters/client/geo-api/GeoApiPlaceMapper';
import KeyAuthHttpClient from '@/adapters/client/KeyAuthHttpClient';
import ReactEnv from '@/adapters/client/ReactEnv';
import HttpError from '@/adapters/HttpError';
import type Mapper from '@/adapters/Mapper';
import type Config from '@/entities/Config';
import type PlaceType from '@/entities/enums/PlaceType';
import type PlaceRepository from '@/entities/repositories/PlaceRepository';
import type Place from '@/entities/types/Place';
import type { PlaceParents } from '@/entities/types/PlaceParents';
import { plural } from '@/helpers/geo/placeType';

class GeoApi implements PlaceRepository {
    private readonly placeMapper: Mapper<GeoApiPlace, Place>;
    private readonly parentsMapper: Mapper<GeoApiParents, PlaceParents>;
    private readonly httpClient: KeyAuthHttpClient;
    private readonly config: Config;

    private readonly baseUrl: string;

    constructor(
        placeMapper: Mapper<GeoApiPlace, Place> = new GeoApiPlaceMapper(),
        parentsMapper: Mapper<GeoApiParents, PlaceParents> = new GeoApiParentsMapper(placeMapper),
        config: Config = new ReactEnv(),
        httpClient: KeyAuthHttpClient = new KeyAuthHttpClient(
            config.get('GATEWAY_API_KEY') as string,
        ),
    ) {
        this.placeMapper = placeMapper;
        this.parentsMapper = parentsMapper;
        this.httpClient = httpClient;
        this.config = config;

        this.baseUrl = config.get('GEOAPI_URL') as string;
    }

    async getItemsByNameLike(
        input: string,
        placeTypes: Array<PlaceType>,
    ): Promise<SearchItemList | []> {
        if (!input) return Promise.resolve([]);

        const placeTypesQuery = placeTypes
            .map((paceType) => `&place_types=${plural(paceType)}`)
            .join('');
        const url = `${urljoin(this.baseUrl, 'autocomplete')}?q=${input}${placeTypesQuery}`;
        try {
            return await this.httpClient.get<SearchItemList>(url);
        } catch (error: unknown) {
            if (error instanceof HttpError && error.status === 422) {
                return Promise.resolve([]);
            }
            throw error;
        }
    }

    async getChildrenByTypeAndId(
        childType: PlaceType,
        type: PlaceType,
        id: number,
    ): Promise<Array<Place>> {
        const url = urljoin(this.baseUrl, plural(type), String(id), plural(childType));
        const geoApiPlaces = await this.httpClient.get<Array<GeoApiPlace>>(url);
        return geoApiPlaces.map((geoApiPlace) => this.placeMapper.perform(geoApiPlace));
    }

    async getByTypeAndId(type: PlaceType, id: number): Promise<Place> {
        const url = urljoin(this.baseUrl, plural(type), `${id}`);
        const geoApiPlace = await this.httpClient.get<GeoApiPlace>(url);
        return this.placeMapper.perform(geoApiPlace);
    }

    async getParentsByTypeAndId(type: PlaceType, id: number): Promise<PlaceParents> {
        const url = urljoin(this.baseUrl, plural(type), `${id}`, 'parents');
        const geoApiParents = await this.httpClient.get<GeoApiParents>(url);
        return this.parentsMapper.perform(geoApiParents);
    }
}

export default GeoApi;
