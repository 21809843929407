import type { SearchItemList } from '@ma-react/components';

import GeoApi from '@/adapters/client/geo-api/GeoApi';
import type PlaceSort from '@/entities/enums/PlaceSort';
import type PlaceType from '@/entities/enums/PlaceType';
import type Place from '@/entities/types/Place';
import type { PlaceParents } from '@/entities/types/PlaceParents';

abstract class PlaceRepository {
    static INSTANCE: PlaceRepository = new GeoApi();

    abstract getItemsByNameLike(
        input: string,
        placeTypes: Array<PlaceType>,
    ): Promise<SearchItemList | []>;
    abstract getByTypeAndId(type: PlaceType, id: number): Promise<Place | undefined>;
    abstract getChildrenByTypeAndId(
        childType: PlaceType,
        type: PlaceType,
        id: number,
        limit?: number,
        sort?: PlaceSort,
    ): Promise<Array<Place>>;
    abstract getParentsByTypeAndId(type: PlaceType, id: number): Promise<PlaceParents>;
}

export default PlaceRepository;
