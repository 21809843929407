enum PlaceType {
    COUNTRY = 'country',
    REGION = 'region',
    GEOCOLLECTION = 'geocollection',
    SUBREGION = 'subregion',
    DISTRICT = 'district',
    CITY = 'city',
    SUBCITY = 'subcity',
    ARRMUN = 'arrmun',
    BOROUGH = 'borough',
    NEIGHBORHOOD = 'neighborhood',
    IRIS = 'iris',
    STREET = 'street',
    BLOCK = 'block',
    PARCELLE = 'parcelle',
    BUILDING = 'building',
    ADDRESS = 'address',
}

export default PlaceType;
