import type {
    GeoApiParent,
    GeoApiParents,
    GeoApiPlace,
} from '@/adapters/client/geo-api/GeoApiPlace';
import type Mapper from '@/adapters/Mapper';
import type PlaceType from '@/entities/enums/PlaceType';
import type Place from '@/entities/types/Place';
import type { PlaceParents } from '@/entities/types/PlaceParents';
import { fromPlural } from '@/helpers/geo/placeType';

class GeoApiParentsMapper implements Mapper<GeoApiParents, PlaceParents> {
    private readonly placeMapper: Mapper<GeoApiPlace, Place>;

    constructor(placeMapper: Mapper<GeoApiPlace, Place>) {
        this.placeMapper = placeMapper;
    }

    perform(item: GeoApiParents): PlaceParents {
        const result: PlaceParents = {};

        Object.entries(item).forEach(([key, value]: [string, GeoApiParent]) => {
            if ((Array.isArray(value) && !value.length) || !value) {
                return;
            }

            if (Array.isArray(value)) {
                result[fromPlural(key)!] = this.placeMapper.perform(value[0]);
                return;
            }

            result[key as PlaceType] = this.placeMapper.perform(value as GeoApiPlace);
        });

        return result;
    }
}

export default GeoApiParentsMapper;
