import type { PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './landing-section.module.scss';

export interface LandingSectionProps {
    backgroundColor?: 'white' | 'grey' | 'blue' | 'purple';
    className?: string;
    debug?: boolean;
    withPadding?: boolean;
}

const LandingSection = ({
    backgroundColor = 'white',
    children,
    className,
    debug = false,
    withPadding = true,
    ...rest
}: PropsWithChildren<LandingSectionProps>) => (
    <section
        className={clsx(
            styles['landing-section'],
            styles[`landing-section--${backgroundColor}`],
            {
                [styles['landing-section--debug']]: debug,
            },
            className,
        )}
        data-testid="landing-section"
        {...rest}
    >
        <div
            className={clsx(styles['landing-section__content'], {
                [styles['landing-section__content--with-padding']]: withPadding,
            })}
        >
            {children}
        </div>
    </section>
);

export default LandingSection;
