import HttpClient from '@/adapters/HttpClient';

class KeyAuthHttpClient {
    private apiKey: string;

    private readonly httpClient: HttpClient;

    constructor(apiKey: string, httpClient: HttpClient = new HttpClient()) {
        this.apiKey = apiKey;
        this.httpClient = httpClient;
    }

    private buildUrl(url: string): string {
        return url.indexOf('?') >= 0
            ? `${url}&apikey=${this.apiKey}`
            : `${url}?apikey=${this.apiKey}`;
    }

    async get<T>(url: string): Promise<T> {
        const urlWithToken = this.buildUrl(url);
        return this.httpClient.get<T>(urlWithToken);
    }
}

export default KeyAuthHttpClient;
