import env from '@beam-australia/react-env';

import Config from '@/entities/Config';

class ReactEnv extends Config {
    get(key: string): boolean | string {
        return Config.booleanCast(env(key));
    }
}

export default ReactEnv;
