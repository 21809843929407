import HttpError from '@/adapters/HttpError';

type Config = { timeout: number; retries: number };
const defaultConfig: Config = { timeout: 10000, retries: 3 };

async function request<T>(url: string, options: RequestInit): Promise<T> {
    const res = await fetch(url, {
        ...options,
    });

    if (res.status >= 400) throw new HttpError(res);

    return res.json();
}

async function abortableRequest<T>(
    url: string,
    options: RequestInit,
    { timeout, retries }: Config,
): Promise<T> {
    retries--;

    try {
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        const result = await request<T>(url, {
            ...options,
            signal: controller.signal,
        });
        clearTimeout(id);
        return result;
    } catch (error: unknown) {
        if (retries <= 0 || (error instanceof HttpError && error.status < 500)) throw error;
        return abortableRequest(url, options, { timeout, retries });
    }
}

class HttpClient {
    private readonly request: <T>(url: string, options: RequestInit, config: Config) => Promise<T>;

    constructor() {
        // Some browsers don't support AbortController, use a fallback with non retried and timeouted request
        try {
            new AbortController();
            this.request = abortableRequest;
        } catch {
            this.request = request;
        }
    }

    async get<T>(
        url: string,
        headers?: { [key: string]: string },
        config: Config = defaultConfig,
    ): Promise<T> {
        const options: RequestInit = {
            headers: {
                Accept: 'application/json',
                ...headers,
            },
            method: 'get',
        };
        return this.request(url, options, config);
    }

    async post<T>(
        url: string,
        body: unknown,
        headers?: { [key: string]: string },
        config: Config = defaultConfig,
    ): Promise<T> {
        const options: RequestInit = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...headers,
            },
            method: 'post',
            body: JSON.stringify(body),
        };
        return this.request(url, options, config);
    }
}

export default HttpClient;
