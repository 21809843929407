import { useState } from 'react';
import type { SearchItem, SearchProps } from '@ma-react/components';
import { Label, Search } from '@ma-react/components';
import clsx from 'clsx';

import type PlaceType from '@/entities/enums/PlaceType';
import HeroCard from '@/front/components/HeroCard';
import { gaEvent } from '@/front/helpers/analytics/Ga';
import useGaSendOnce from '@/front/hooks/useGaSendOnce';
import PlaceSearchAutocomplete from '@/use-cases/client/PlaceSearchAutocomplete';

import styles from './hero-search-card.module.scss';

const placeSearchAutocomplete = PlaceSearchAutocomplete.INSTANCE;

const fetchFromGeo = (searchString: string, placeTypes: Array<PlaceType>) => {
    return placeSearchAutocomplete.perform(encodeURIComponent(searchString), placeTypes);
};

export interface HeroSearchCardProps {
    className?: string;
    label: string;
    placeholder?: string;
    placeTypes: Array<PlaceType>;
    gaData: {
        actionPrefix: string;
        label: string;
        category: string;
    };
    onSubmit: (selectedItem?: SearchItem | null) => void;
    searchProps?: Partial<SearchProps>;
    withSearchIcon?: boolean;
    submitLabel?: string;
    isLoading?: boolean;
}

const HeroSearchCard = ({
    className,
    label,
    placeholder,
    placeTypes,
    gaData,
    onSubmit,
    isLoading = false,
    searchProps = {},
}: HeroSearchCardProps) => {
    const [hasError, setHasError] = useState(false);

    const sendFormEvent = (type: 'error' | 'success' | 'attempt') => {
        gaEvent({
            category: gaData.category,
            action: `${gaData.actionPrefix}:submit:${type}`,
            label: gaData.label,
        });
    };
    const engageEvent = {
        category: gaData.category,
        action: `${gaData.actionPrefix}:started`,
        label: gaData.label,
    };
    const [sendStartedEvent] = useGaSendOnce(engageEvent);

    return (
        <HeroCard className={className} data-testid="hero-search-card">
            <div className={styles['hero-search-card__label']}>
                <Label className={styles['hero-search-card__label-text']}>{label}</Label>
            </div>
            <Search
                buttonProps={{
                    type: 'submit',
                    className: clsx(styles['hero-search-card__button'], {
                        [styles['hero-search-card__button--error']]: hasError,
                    }),
                    loading: isLoading,
                    disabled: isLoading,
                }}
                className={styles['hero-search-card__search']}
                events={{
                    onError: () => {
                        sendFormEvent('error');
                    },
                    onErrorToggle: (errorStatus) => {
                        setHasError(errorStatus);
                    },
                    onAttempt: () => sendFormEvent('attempt'),
                    onSuccess: () => sendFormEvent('success'),
                }}
                fetchFunction={(searchString) => fetchFromGeo(searchString, placeTypes)}
                inputProps={{
                    'aria-label': label,
                }}
                placeholder={placeholder}
                onInputValueChange={() => {
                    sendStartedEvent();
                }}
                onSubmit={onSubmit}
                {...searchProps}
            />
        </HeroCard>
    );
};

export default HeroSearchCard;
