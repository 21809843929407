import PlaceType from '@/entities/enums/PlaceType';

const pluralByPlaceType = new Map<PlaceType, string>()
    .set(PlaceType.COUNTRY, 'countries')
    .set(PlaceType.REGION, 'regions')
    .set(PlaceType.GEOCOLLECTION, 'geocollections')
    .set(PlaceType.SUBREGION, 'subregions')
    .set(PlaceType.DISTRICT, 'districts')
    .set(PlaceType.CITY, 'cities')
    .set(PlaceType.SUBCITY, 'subcities')
    .set(PlaceType.ARRMUN, 'arrmuns')
    .set(PlaceType.BOROUGH, 'boroughs')
    .set(PlaceType.NEIGHBORHOOD, 'neighborhoods')
    .set(PlaceType.IRIS, 'irises')
    .set(PlaceType.STREET, 'streets')
    .set(PlaceType.BLOCK, 'blocks')
    .set(PlaceType.PARCELLE, 'parcelles')
    .set(PlaceType.BUILDING, 'buildings')
    .set(PlaceType.ADDRESS, 'addresses');

const placeTypeByPlural = new Map<string, PlaceType>();
pluralByPlaceType.forEach((value: string, key: PlaceType) => {
    placeTypeByPlural.set(value, key);
});

export function plural(placeType: PlaceType): string {
    return pluralByPlaceType.get(placeType) || '';
}
export function fromPlural(key: string): PlaceType | undefined {
    return placeTypeByPlural.get(key);
}

const abbrevationByPlaceType = new Map<PlaceType, string>()
    .set(PlaceType.COUNTRY, 'Pays')
    .set(PlaceType.REGION, 'Région')
    .set(PlaceType.GEOCOLLECTION, 'GeoCollection')
    .set(PlaceType.SUBREGION, 'Dép.')
    .set(PlaceType.DISTRICT, 'Dist.')
    .set(PlaceType.CITY, 'Ville')
    .set(PlaceType.SUBCITY, 'Ville')
    .set(PlaceType.ARRMUN, 'Arr.')
    .set(PlaceType.BOROUGH, 'Quart.')
    .set(PlaceType.NEIGHBORHOOD, 'Voisinnage')
    .set(PlaceType.IRIS, 'IRIS')
    .set(PlaceType.STREET, 'Rue')
    .set(PlaceType.BLOCK, 'Bloc')
    .set(PlaceType.PARCELLE, 'Parcelle')
    .set(PlaceType.BUILDING, 'Bâtiment')
    .set(PlaceType.ADDRESS, 'Adr.');

export function abbreviation(placeType: PlaceType): string {
    return abbrevationByPlaceType.get(placeType) || '';
}

const labelByPlaceType = new Map<PlaceType, string>()
    .set(PlaceType.COUNTRY, 'Pays')
    .set(PlaceType.REGION, 'Région')
    .set(PlaceType.GEOCOLLECTION, 'GeoCollection')
    .set(PlaceType.SUBREGION, 'Département')
    .set(PlaceType.DISTRICT, 'District')
    .set(PlaceType.CITY, 'Ville')
    .set(PlaceType.SUBCITY, 'Ville')
    .set(PlaceType.ARRMUN, 'Arrondissement')
    .set(PlaceType.BOROUGH, 'Quartier')
    .set(PlaceType.NEIGHBORHOOD, 'Voisinnage')
    .set(PlaceType.IRIS, 'IRIS')
    .set(PlaceType.STREET, 'Rue')
    .set(PlaceType.BLOCK, 'Bloc')
    .set(PlaceType.PARCELLE, 'Parcelle')
    .set(PlaceType.BUILDING, 'Bâtiment')
    .set(PlaceType.ADDRESS, 'Adresse');

export function label(placeType: PlaceType): string {
    return labelByPlaceType.get(placeType) || '';
}
