import { translate } from '@ma-js-common/translate';

import PlaceType from '@/entities/enums/PlaceType';
import PlaceRepository from '@/entities/repositories/PlaceRepository';

const geoTypeLabels = {
    [PlaceType.COUNTRY]: translate('Pays'),
    [PlaceType.REGION]: translate('Région'),
    [PlaceType.SUBREGION]: translate('Dépt.'),
    [PlaceType.CITY]: translate('Ville'),
    [PlaceType.SUBCITY]: translate('Ville'),
    [PlaceType.ARRMUN]: translate('Arr.'),
    [PlaceType.BOROUGH]: translate('Quart.'),
    [PlaceType.STREET]: translate('Rue'),
    [PlaceType.ADDRESS]: translate('Adr.'),
};

type GeoTypeLabel =
    | PlaceType.COUNTRY
    | PlaceType.REGION
    | PlaceType.SUBREGION
    | PlaceType.CITY
    | PlaceType.SUBCITY
    | PlaceType.ARRMUN
    | PlaceType.BOROUGH
    | PlaceType.STREET
    | PlaceType.ADDRESS;

class PlaceSearchAutocomplete {
    static INSTANCE = new PlaceSearchAutocomplete();
    private readonly placeRepository: PlaceRepository;

    constructor(placeRepository: PlaceRepository = PlaceRepository.INSTANCE) {
        this.placeRepository = placeRepository;
    }

    perform(input: string, placeTypes: Array<PlaceType>) {
        return this.placeRepository.getItemsByNameLike(input, placeTypes).then((dataJson) => {
            return dataJson.map(({ id, display_name, slug, type, coordinates, name }) => {
                return {
                    id,
                    value: display_name,
                    slug,
                    label: geoTypeLabels[type as GeoTypeLabel],
                    type,
                    coordinates,
                    name,
                };
            });
        });
    }
}

export default PlaceSearchAutocomplete;
